<template>
  <b-table
    ref="refInvoiceListTable"
    :items="directOrders"
    responsive
    :fields="tableUserDirectOrdersColumns"
    primary-key="id"
    show-empty
    empty-text="Aucune Commande récurrente effectuée!"
    class="position-relative"
  >
    <!-- Column: Service -->
    <template #cell(recurring_service)="data">
      <span class="text-info font-small-4">
        {{ data.item.recurring_service.name }}
      </span>
    </template>

    <!-- Column: Budget -->
    <template #cell(budget)="data">
      <span class="text-info font-small-4">
        {{ data.item.budget }}
      </span>
    </template>

    <!-- Column: Issued Date -->
    <template #cell(created_at)="data">
      <span class="text-info font-small-4">
        {{ moment(data.item.created_at).locale("fr").format("llll") }}
      </span>
    </template>

    <!-- Column: Status -->
    <template #cell(status)="data">
      <b-badge
        pill
        :variant="`light-${resolveOrderStatusVariant(
          data.item.status,
          data.item.placement_cost_paid,
          data.item.search_employee
        )}`"
        class="text-capitalize font-small-4"
      >
        {{

          resolveStatusText(data.item)
        }}
      </b-badge>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <!-- Dropdown -->
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="20"
              class="align-middle text-center text-info"
            />
          </template>
          <b-dropdown-item
            :id="`details${data.item.id}`"
            class="cursor-pointer itemActions"
            @click="putOrderToStore(data.item)"
          >
            <feather-icon
              icon="EyeIcon"
              class="text-info"
              size="20"
            />
            <span
              id="spanActions"
              class="align-middle ml-50"
            >Voir la commande</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },

  data() {
    return {
      directOrders: null,
      tableUserDirectOrdersColumns: [
        { key: 'recurring_service', label: 'Service demandé', sortable: true },
        { key: 'budget', label: 'Budget', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created_at', label: 'Date de la commande', sortable: true },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters('customers', ['getCustomerDirectOrders']),
  },

  created() {
    this.getCustomerDirectOrdersAction(this.$route.params.id).then(
      response => {
        this.directOrders = response.data
      },
    )
  },

  methods: {
    ...mapActions('customers', ['getCustomerDirectOrdersAction']),

    putOrderToStore(order) {
      this.$store.commit('orders/SET_DIRECT_ORDER', null)
      this.$router.push({
        name: 'admin-orders-recurring-details',
        params: { id: order.id },
      })
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'danger'
      if (
        status === 0
        && placementCostPaid === false
        && searchEmployee === true
      ) {
        return 'danger'
      }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },
    resolveStatusText(element) {
      if (
        element.status === 0
        && element.placement_cost_paid === false
        && element.search_employee === true
      ) {
        return 'Non Payé'
      }
      if (element.status === 0 && element.feedback === null) {
        return 'En attente de feedback'
      }

      if (
        element.status === 0
        && element.feedback != null
        && element.recommended_employees_count === 0
      ) {
        return 'En attente de recommandation'
      }

      if (
        element.status === 0
        && element.feedback != null
        && element.proposed_employees_count === 0
      ) {
        return 'En attente de proposition'
      }
      if (element.status === -1) {
        return 'Résilié'
      }
      if (element.status === 1) {
        return 'Employé proposé'
      }
      if (element.status === 2) {
        return 'Contract émis'
      }
      if (element.status === 3) {
        return 'Contract approuvé'
      }
      if (element.status === 4) {
        return 'Actif'
      }
      if (element.status === 5) {
        return 'Terminée'
      }
      return `${element.status} ${element.feedback} ${element.proposed_employees_count} ${element.recommended_employees_count}  ${element.placement_cost_paid} ${element.search_employee}`
    },
  },
}
</script>
<style>
.itemActions :hover {
  background: transparent !important;
}
.itemActions #spanActions :hover {
  background: transparent !important;
}
</style>
