<template>
  <div
    v-if="
      getUser.role.slug === 'responsable-commercial' ||
      getUser.role.slug === 'responsable-relation-client' ||
      getUser.role.slug === 'charge-daffaires' ||
      getUser.role.slug === 'charge-de-clientele' ||
      getUser.role.slug === 'super-administrateur'
    "
  >
  <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/customers">Liste Clients</b-breadcrumb-item>
      <b-breadcrumb-item active>Détails</b-breadcrumb-item>
    </b-breadcrumb>
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div
      v-else-if="isError"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-danger">
        <feather-icon icon="XCircleIcon" size="100" />
      </h1>
      <p class="font-medium-1 text-danger font-weight-normal">
        Aucun Client ne correspond à cet identifiant
      </p>
    </div>
    <div v-else>
      <template>
        <b-card>
          <b-tabs>
            <b-tab active title="Informations du client">
              <customer-informations :account-info="accountData" />
            </b-tab>

            <b-tab title="Portefeuille du client">
              <div v-if="customer.is_activated">
                <span
                  :class="[
                    walletCustomer.balance > 0 ? 'text-success' : 'text-danger',
                  ]"
                  class="d-flex font-large-1"
                >
                  <span class="font-large-1 mb-2"
                    >SOLDE {{ walletCustomer.balance }} XOF</span
                  >
                </span>
                <b-row>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-2"
            v-if="getUser.role.slug == 'super-administrateur'"
          >
            <b-button
              variant="outline-info"
              @click="showWalletOperationModal()"
            >
              <span class="text-nowrap font-medium-1">Faire un retrait</span>
            </b-button>

          </b-col>
         
        </b-row>
                <b-table
                  ref="refInvoiceListTable"
                  :items="walletCustomer.logs"
                  responsive
                  :fields="tableWalletLogsColumns"
                  primary-key="id"
                  show-empty
                  empty-text="Aucune opération effectuée."
                  class="position-relative"
                >
                  <!-- Column: Issued Date -->
                  <template #cell(balance_before_operation)="data">
                    <span class="text-info font-small-3">
                      XOF {{ data.item.balance_before_operation }}
                    </span>
                  </template>
                  <template #cell(balance_after_operation)="data">
                    <span class="text-info font-small-3">
                      XOF {{ data.item.balance_after_operation }}
                    </span>
                  </template>
                  <template #cell(operation_type)="data">
                    <b-badge
                      pill
                      :variant="`light-${resolveOrderStatusVariant(
                        data.item.operation_type
                      )}`"
                      class="text-capitalize font-small-3"
                    >
                      {{ resolveStatusText(data.item.operation_type) }}
                    </b-badge>
                  </template>
                </b-table>
              </div>
              <div v-else>Compte non activé</div>
            </b-tab>
            <!-- <b-tab title="Commandes ponctuelles">
              <div v-if="customer.is_activated">
                <customer-orders />
              </div>
              <div v-else>Compte non activé</div>
            </b-tab>

            <b-tab title="Commandes récurrentes">
              <div v-if="customer.is_activated">
                <customer-direct-orders />
              </div>
              <div v-else>Compte non activé</div>
            </b-tab> -->
          </b-tabs>
        </b-card>
      </template>
    </div>

    <b-modal
      id="modal-new-wallet-operation"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Retrait du portefeuille du client"
    >
      <validation-observer
        #default="{}"
        ref="paymentForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyCustomerWalletOperationAction"
        >
          <!-- Email -->


          <validation-provider
            #default="{ errors }"
            designation="designation"
            rules="required"
          >
            <b-form-group
              label="Libellé"
              label-for="designation"
            >
              <b-form-input
                id="designation"
                v-model="payload.designation"
                type="text"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            designation="amount"
            rules="required"
          >
            <b-form-group
              label="Montant"
              label-for="amount"
            >
              <b-form-input
                id="amount-add"
                v-model="payload.amount_add"
                type="number"
                :state="errors.length > 0 ? false : null"
                trim
                
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isPaymentLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isPaymentLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Confirmer</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BFormTextarea,
  BFormGroup,
  // BMedia,
  BAvatar,
  BLink,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  BCardHeader,
  BFormSelect,
  BCardBody,
  // BPagination,
  // BTooltip,
  BSpinner,
  BTab,
  BTabs,
  BBreadcrumb,
  BBreadcrumbItem
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";
import CustomerOrders from "./CustomerOrders.vue";
import CustomerInformations from "./CustomerInformations.vue";
import CustomerDirectOrders from "./CustomerDirectOrders.vue";
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BFormTextarea,
    BFormSelect,
    BForm,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BAlert,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BMedia,
    BAvatar,
    BLink,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    // BDropdown,
    // BDropdownItem,
    // BPagination,
    // BTooltip,
    BTab,
    BTabs,
    BBreadcrumb,
    BBreadcrumbItem,
    CustomerOrders,
    CustomerDirectOrders,
    CustomerInformations,
    vSelect
  },
  data() {
    return {
      payload: {

      },
      isPaymentLoading: false,
      amount_add: null,
      isPaymentLoading: false,
      isPageLoading: true,
      isError: false,
      isUpdateInfo: false,
      customer: null,
      walletCustomer: null,
      isWalletCustomerLoading: true,
      tableWalletLogsColumns: [
        { key: "operation_type", label: "Type d'opération", sortable: true },
        {
          key: "balance_before_operation",
          label: "Montant avant l'opération",
          sortable: true,
        },
        {
          key: "balance_after_operation",
          label: "Montant après l'opération",
          sortable: true,
        },
        { key: "trace", label: "Description", sortable: true },
      ],
      avatarText,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("customers", ["getCustomer"]),

    accountData() {
      return {
        id:this.customer.id,
        avatar: this.customer.profile_image,
        firstName: this.customer.first_name,
        lastName: this.customer.last_name,
        email: this.customer.email,
        phoneNumber: this.customer.phone_number.replace("229", ""),
        is_company: this.customer.is_company,
        company_address: this.customer.company_address,
        company_ifu: this.customer.company_ifu,
        company_name: this.customer.company_name,

      };
    },

    phoneNumber() {
      return this.updatePayload.phone_number.replace(/\s/g, "");
    },
  },
  watch: {},
  created() {
    console.log( this.getCustomer)
    if (this.getCustomer != null) {
      this.isPageLoading = false;
      this.customer = this.getCustomer;
      this.getCustomerWalletAction(this.$route.params.id)
        .then((response) => {
          this.isWalletCustomerLoading = false;
          this.walletCustomer = response.data.wallet;
          console.log("Wallet customer", this.walletCustomer)
        })
        .catch((error) => {
          this.isWalletCustomerLoading = true;
        });
    } else {
      this.getCustomerByIdAction(this.$route.params.id)
        .then((response) => {
          this.isPageLoading = false;
          this.customer = response.data;
          this.getCustomerWalletAction(this.$route.params.id)
            .then((response) => {
              this.isWalletCustomerLoading = false;
              this.walletCustomer = response.data.wallet;
              console.log("Wallet customer", this.walletCustomer)
            })
            .catch((error) => {
              this.isWalletCustomerLoading = true;
            });
        })
        .catch((error) => {
          this.isPageLoading = true;
          this.isError = true;
        });
    }
  },
  methods: {
    ...mapActions("customers", [
      "getCustomerByIdAction",
      "getCustomerWalletAction",
      "updateCustomerInfo",
      "withdrawInCustomerWalletAction"
    ]),
    showWalletOperationModal() {
      this.$bvModal.show('modal-new-wallet-operation')
    },
    hideModal(){
      this.$bvModal.hide('modal-new-wallet-operation')
      this.payload = {

      }
    },
    applyCustomerWalletOperationAction(){
      this.isPaymentLoading = true
      this.withdrawInCustomerWalletAction({walletId: this.walletCustomer.id, payload: this.payload})
      .then(response =>{
        this.isPaymentLoading = false
        console.log(response)
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
      })
      .catch(error =>{
        this.isPaymentLoading = false
        console.log(error)
        this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
      })

    },
    applyGetCustomerWalletAction() {
      this.getCustomerWalletAction(this.$route.params.id)
        .then((response) => {
          this.isWalletCustomerLoading = false;
          this.walletCustomer = response.data;
          console.log(this.walletCustomer);
        })
        .catch((error) => {});
    },
    resolveOrderStatusVariant(status) {
      if (status === true) {
        return "success";
      }
      return "danger";
    },

    resolveStatusText: (status) => {
      if (status === true) {
        return "Dépôt";
      }
      return "Retrait";
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

