<template>
  <div
    v-if="
      getUser.role.slug === 'responsable-commercial' ||
      getUser.role.slug === 'responsable-relation-client' ||
      getUser.role.slug === 'charge-daffaires' ||
      getUser.role.slug === 'charge-de-clientele' ||
      getUser.role.slug === 'super-administrateur'
    "
  >
    <b-card>
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              v-if="optionsLocal.avatar != ''"
              ref="previewEl"
              height="90"
              width=""
              rounded
              :src="optionsLocal.avatar"
            />
            <b-avatar
              size="104px"
              rounded
              v-else
              :text="
                avatarText(
                  `${optionsLocal.firstName} ` + ` ${optionsLocal.lastName}`
                )
              "
            >
            </b-avatar>
          </b-link>
          <!--/ avatar -->
        </b-media-aside>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <validation-observer #default="{}" ref="updateInfo">
        <b-form class="mt-2" autocomplete="off">
          <b-row>
            <b-col>
               <!-- Type de compte -->
              <validation-provider
                #default="{ errors }"
                name="compteType"
                rules="required"
              >
                <b-form-group
                  label="Type du compte"
                  label-for="compteType"
                >
                  <b-form-select
                    id="compteType"
                    v-model="optionsLocal.is_company"
                    :options="compteOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="Nom du client"
                rules="required"
              >
                <b-form-group
                  :label="optionsLocal.is_company == 1 ?'Nom du dirigeant' : 'Nom du client' "
                  label-for="account-username"
                >
                  <b-form-input
                    id="account-username"
                    v-model="optionsLocal.lastName"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="Prénoms du client"
                rules="required"
              >
                <b-form-group
                  :label="optionsLocal.is_company == 1 ?'Prénom du dirigeant' : 'Prénom du client' "
                  label-for="account-name"
                >
                  <b-form-input
                    id="account-name"
                    v-model="optionsLocal.firstName"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-group label="Email" label-for="account-e-mail">
                  <b-form-input
                    id="account-e-mail"
                    v-model="optionsLocal.email"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                name="Numéro de téléphone "
                rules="required"
              >
                <b-form-group
                  label="Numéro de téléphone"
                  label-for="account-phone-number"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      :class="errors[0] ? 'border-danger' : ''"
                    >
                      BJ (+229)
                    </b-input-group-prepend>
                    <cleave
                      id="phone_number"
                      v-model="optionsLocal.phoneNumber"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
             <!-- Numero IFU -->
              <validation-provider
                #default="{ errors }"
                name="ifu"
                rules="length:13"
                
              >
                <b-form-group
                  label="Numéro IFU"
                  label-for="ifu"            
                >
                  <b-form-input
                    id="ifu"
                    v-model="optionsLocal.company_ifu"

                    type="number"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
          </b-col>
            <b-col>
               <!-- Entreprise Name -->
                <validation-provider
                  #default="{ errors }"
                  name="nomEntreprise"
                  rules="required" 
                  v-if="optionsLocal.is_company"
                >
                  <b-form-group
                    label="Nom de l'entreprise "
                    label-for="nomEntreprise"
                  >
                    <b-form-input
                      id="nomEntreprise"
                      v-model="optionsLocal.company_name"
                      autofocus
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
            </b-col>
           
          <b-col>
          <!--Company  Address -->
              <validation-provider
                #default="{ errors }"
                name="siège de l'entreprise"
                rules="required"
                v-if="optionsLocal.is_company == 1"
              >
                <b-form-group
                  label="Siège de l'entreprise"
                  label-for="company_address"
                >
                  <b-form-input
                    id="company_address"
                    v-model="optionsLocal.company_address"
                    placeholder="Siège de l'entreprise"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-col>

            <!-- alert -->
            <b-col cols="12" class="mt-75"> </b-col>
            <!--/ alert -->

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="isProfileInfoLoading"
                @click="applyChangeProfileInfoAction()"
                class="mt-2 mr-1"
              >
                <div v-if="isProfileInfoLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else>Sauvegarder</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>
  
  <script>
import {
  BFormFile,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
  BFormSelect,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.bj";
import { required, alphaNum } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BSpinner,
    BAvatar,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    accountInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("customers", ["getCustomer"]),
    phoneNumber() {
      return this.optionsLocal.phoneNumber.replace(/\s/g, "");
    },
  },
  data() {
    return {
       compteOptions: [
        {
          value: true,
          text: 'Compte Entreprise',
        },
        {
          value: false,
          text: 'Compte Personnel',
        }
      ],
      required,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "BJ",
        },
      },
      optionsLocal: this.accountInfo,
      /* optionsLocal: JSON.parse(JSON.stringify(this.accountInfo)), */
      avatarText,
      isProfileInfoLoading: false,
    };
  },

  created () {
    console.log(this.optionsLocal)
  },
  methods: {
    ...mapActions("customers", [
      "updateCustomerInfo", "getCustomersAction"
    ]),
    
    applyChangeProfileInfoAction() {
      if (
        this.getCustomer.last_name !== this.optionsLocal.lastName ||
        this.getCustomer.first_name !== this.optionsLocal.firstName ||
        this.getCustomer.phone_number !== `229${this.phoneNumber}` || 
        this.getCustomer.email !== this.optionsLocal.email ||
        this.getCustomer.is_company != this.optionsLocal.is_company ||
        this.getCustomer.company_address != this.optionsLocal.company_address ||
        this.getCustomer.company_ifu != this.optionsLocal.company_ifu ||
        this.getCustomer.company_name != this.optionsLocal.company_name 
      ) {

        this.$refs.updateInfo.validate().then((success) => {
        if (success) {
          this.isProfileInfoLoading = true;
          let payload = {
          first_name: this.optionsLocal.firstName,
          last_name: this.optionsLocal.lastName,
          email: this.optionsLocal.email,
          phone_number: `229${this.phoneNumber}`,
          is_company: this.optionsLocal.is_company ? 1 : 0,
          company_address: this.optionsLocal.company_address,
          company_ifu: this.optionsLocal.company_ifu,
          company_name: this.optionsLocal.company_name,
        };
          this.updateCustomerInfo({
            payload: payload,
            user: this.optionsLocal.id,
          })
            .then((response) => {
              this.isProfileInfoLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.getCustomersAction()
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Erreur",
                    icon: "TriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.isProfileInfoLoading = false;
            });
        }
      });

      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Attention!",
              icon: "AlertTriangleIcon",
              text: "Aucune donnée n'est modifiée",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      }
    },
  },
};
</script>
  <style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>