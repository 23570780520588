var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.getUser.role.slug === 'responsable-commercial' ||
    _vm.getUser.role.slug === 'responsable-relation-client' ||
    _vm.getUser.role.slug === 'charge-daffaires' ||
    _vm.getUser.role.slug === 'charge-de-clientele' ||
    _vm.getUser.role.slug === 'super-administrateur'
  )?_c('div',[_c('b-card',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[(_vm.optionsLocal.avatar != '')?_c('b-img',{ref:"previewEl",attrs:{"height":"90","width":"","rounded":"","src":_vm.optionsLocal.avatar}}):_c('b-avatar',{attrs:{"size":"104px","rounded":"","text":_vm.avatarText(
                (_vm.optionsLocal.firstName) + " " + " " + (_vm.optionsLocal.lastName)
              )}})],1)],1)],1),_c('validation-observer',{ref:"updateInfo",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"mt-2",attrs:{"autocomplete":"off"}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"compteType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type du compte","label-for":"compteType"}},[_c('b-form-select',{attrs:{"id":"compteType","options":_vm.compteOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.optionsLocal.is_company),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "is_company", $$v)},expression:"optionsLocal.is_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Nom du client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.optionsLocal.is_company == 1 ?'Nom du dirigeant' : 'Nom du client',"label-for":"account-username"}},[_c('b-form-input',{attrs:{"id":"account-username","type":"text","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.optionsLocal.lastName),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "lastName", $$v)},expression:"optionsLocal.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Prénoms du client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.optionsLocal.is_company == 1 ?'Prénom du dirigeant' : 'Prénom du client',"label-for":"account-name"}},[_c('b-form-input',{attrs:{"id":"account-name","type":"text","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.optionsLocal.firstName),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "firstName", $$v)},expression:"optionsLocal.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"id":"account-e-mail","type":"text","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.optionsLocal.email),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "email", $$v)},expression:"optionsLocal.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro de téléphone ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de téléphone","label-for":"account-phone-number"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : '',attrs:{"is-text":""}},[_vm._v(" BJ (+229) ")]),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"phone_number","raw":false,"options":_vm.options.phone},model:{value:(_vm.optionsLocal.phoneNumber),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "phoneNumber", $$v)},expression:"optionsLocal.phoneNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"ifu","rules":"length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro IFU","label-for":"ifu"}},[_c('b-form-input',{attrs:{"id":"ifu","type":"number","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.optionsLocal.company_ifu),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "company_ifu", $$v)},expression:"optionsLocal.company_ifu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[(_vm.optionsLocal.is_company)?_c('validation-provider',{attrs:{"name":"nomEntreprise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom de l'entreprise ","label-for":"nomEntreprise"}},[_c('b-form-input',{attrs:{"id":"nomEntreprise","autofocus":"","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.optionsLocal.company_name),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "company_name", $$v)},expression:"optionsLocal.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e()],1),_c('b-col',[(_vm.optionsLocal.is_company == 1)?_c('validation-provider',{attrs:{"name":"siège de l'entreprise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Siège de l'entreprise","label-for":"company_address"}},[_c('b-form-input',{attrs:{"id":"company_address","placeholder":"Siège de l'entreprise","state":errors.length > 0 ? false : null},model:{value:(_vm.optionsLocal.company_address),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "company_address", $$v)},expression:"optionsLocal.company_address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1),_c('b-col',{staticClass:"mt-75",attrs:{"cols":"12"}}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":_vm.isProfileInfoLoading},on:{"click":function($event){return _vm.applyChangeProfileInfoAction()}}},[(_vm.isProfileInfoLoading)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Sauvegarder")])])],1)],1)],1)]}}],null,false,1031404947)})],1)],1):_c('div',[_c('not-authorized')],1)}
var staticRenderFns = []

export { render, staticRenderFns }