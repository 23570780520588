<template>
  <b-table
    ref="refInvoiceListTable"
    :items="orders"
    responsive
    :fields="tableUserOrdersColumns"
    primary-key="id"
    show-empty
    empty-text="Aucune commande ponctuelle effectuée."
    class="position-relative"
  >
    <!-- Column: Service -->
    <template #cell(service)="data">
      <span class="text-info font-small-4">
        {{ data.item.service.name }}
      </span>
    </template>

    <!-- Column: Budget -->
    <template #cell(budget)="data">
      <span class="text-info font-small-4">
        {{ data.item.budget }}
      </span>
    </template>

    <!-- Column: Issued Date -->
    <template #cell(created_at)="data">
      <span class="text-info font-small-4">
        {{ moment(data.item.created_at).locale("fr").format("llll") }}
      </span>
    </template>

    <!-- Column: Status -->
    <template #cell(status)="data">
      <b-badge
        pill
        :variant="`light-${resolveOrderStatusVariant(data.item.status)}`"
        class="text-capitalize font-small-4"
      >
        {{ resolveStatusText(data.item.status) }}
      </b-badge>
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <!-- Dropdown -->
        <b-dropdown variant="link" toggle-class="p-0" no-caret>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="20"
              class="align-middle text-center text-info"
            />
          </template>
          <b-dropdown-item
            class="cursor-pointer itemActions"
            @click="goToDetails(data.item)"
            :id="`details${data.item.id}`"
          >
            <feather-icon icon="EyeIcon" class="text-info" size="20" />
            <span id="spanActions" class="align-middle ml-50"
              >Voir la commande</span
            >
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
      BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
  },

  data() {
    return {
      orders: null,
      tableUserOrdersColumns: [
        { key: "service", label: "Service demandé", sortable: true },
        { key: "budget", label: "Budget", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "created_at", label: "Date de la commande", sortable: true },
        { key: "actions" },
      ],
    };
  },

  computed: {
    ...mapGetters("customers", ["getCustomerOrders"]),
  },

  created() {
    this.getCustomerOrdersAction(this.$route.params.id).then((response) => {
      this.orders = response.data;
    });
  },

  methods: {
    ...mapActions("customers", ["getCustomerOrdersAction"]),

    goToDetails(order) {
      this.$store.commit("orders/SET_ORDER", null);
      this.$router.push({
        name: "admin-orders-punctual-details",
        params: { id: order.id },
      });
    },

    resolveOrderStatusVariant: (status) => {
      if (status === 1) return "info";
      if (status === 0) return "warning";
      if (status === 3) return "success";
      return "primary";
    },

    resolveStatusText: (status) => {
      if (status === 1) return "Offre proposée";
      if (status === 0) return "En attente";
      if (status === 3) return "Terminée";
      return "Payée";
    },
  },
};
</script>
<style>
.itemActions :hover {
  background: transparent !important;
  color: white;
}
.itemActions #spanActions :hover {
  background: transparent !important;
}
</style>
